import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import { FlowProps } from './RegisterFlow';

const RegisterLanding: FC<FlowProps> = (props) => {
  const { next } = props;

  const { t } = useTranslation('auth');

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('registration.landing.heading')}</Heading>
      <div>{t('registration.landing.subheading')}</div>
      <div className="w-96">
        <div className="px-10 text-center">
          <Button className="mt-6 w-full" type={ButtonType.PRIMARY} onClick={next} data-cy="register-next">
            <span className="font-medium">{t('registration.landing.buttons.next')}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegisterLanding;
